body {
  font-family: Inter, Arial, sans-serif;
  color: #FFF;
  margin: 0 auto;
  background: #000;
}
.container {
  width: 90%;
  margin: auto;
  padding: 20px 0;
}
header, section, footer {
  padding: 60px 0;
  text-align: center;
}
button {
  font-size: 16px;
  line-height: 22px;
  padding: 12px 29px;
  color: #FFF;
  vertical-align: middle;
  background: linear-gradient(90deg, #222, #000) padding-box, linear-gradient(48.23deg, #22B879 20%, #7441D5 80%) border-box;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margin: 0px 10px;
}
button:hover {
  background: linear-gradient(90deg, #262626, #060606) padding-box, linear-gradient(48.23deg, #22B879 40%, #7441D5 60%) border-box;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #000;
}

::-webkit-scrollbar-thumb {
  background-color: #111b1dff;
  outline: 1px solid rgb(45, 48, 50);
  border-radius:3px;
}
input[type=text] {
  width: 100%;
  height: 24px;
  text-align: center;
  font-size: 13px;
}

input[type=number] {
  width: 100%;
  height: 24px;
  text-align: center;
  font-size: 13px;
}
a {
  color: #EEE;
}
a:hover {
  color: #FFF;
}
.grey {
  color: #888;
}
.green {
  color: #22B879;
}
.purple {
  color: #7441D5;
}
.text-big {
  font-size: 1.2em;
}
.text-small {
  font-size: 0.8em;
}
.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.spacer {
  margin: 2%;
}
.hidden {
  display: none;
}
.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}
.flex-item {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}
.flex-grow {
  flex-grow: 1;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.flex-middle {
  align-items: center;
}