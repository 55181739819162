h1 {
    font-family: "Playfair Display", 'Times New Roman', Times, serif;
    font-weight: 400;
    margin: 20px auto 30px auto;
    text-align: center;
    background: linear-gradient(90deg, #22B879 40%, #6C63FF 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    letter-spacing: 10px;
    font-size: 22px;
}

.rotate-notice .logo {
    font-family: "Playfair Display", 'Times New Roman', Times, serif;
    font-size: 25px;
    margin-top: -50px;
}
.header .logo {
    font-family: "Playfair Display", 'Times New Roman', Times, serif;
    font-size: 20px;
}

.react-tabs {
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
}

.react-tabs li {
    color: #666;
    font-family: Inter, Arial, sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
}

.react-tabs__tab--selected {
    color: #22B879 !important;
    background: none;
    border: none;
    border-bottom: 1px solid #6C63FF;
}

.react-tabs__tab:focus:after {
    height: 0px
}

.react-tabs__tab-panel {
    padding: 30px;
    font-family: Inter, Arial, sans-serif;
    font-size: 12px;
}

.wallet-container {
    margin: 40px auto;
    max-width: 600px;
}

div:where(.swal2-container) div:where(.swal2-popup) {
    background: #000000DD;
    border: 1px solid #6C63FF;
}

.swal2-styled {
        font-size: 16px;
        line-height: 22px;
        padding: 12px 29px;
        color: #FFF;
        vertical-align: middle;
        background: linear-gradient(90deg, #222, #000) padding-box, linear-gradient(48.23deg, #22B879 20%, #7441D5 80%) border-box;
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        border-radius: 15px;
        cursor: pointer;
        letter-spacing: 1px;
        margin: 0px 10px;
}
.swal2-styled:hover {
    background: linear-gradient(90deg, #262626, #060606) padding-box, linear-gradient(48.23deg, #22B879 40%, #7441D5 60%) border-box !important;
}

.asset-table {
    width: 300px;
    margin: 0px auto 40px auto;
    border: 1px solid #444;
    border-radius: 5px;
    padding: 5px;
}
.asset-table th {
    color: #444;
    padding: 5px;
}

#refresh-balances {
    position: relative;
    bottom: -30px;
}

.game-card {
    padding: 20px;
    margin: 5px;
    border: 1px solid #333;
    border-radius: 15px;
    background: #111;
    height: 200px;
    width: 150px;
}

.game-card button {
    font-size: 0.9em;
    padding: 5px 15px;
    margin-top: 15px;
}

.game-card img {
    width: 120px;
    margin: 10px auto;
}

@media screen and (orientation: landscape) {
    .rotate-notice {
        display: none;
    }
}
@media screen and (orientation: portrait) {
    .rotate-notice {
        display: block;
        width: 100%;
        height: 100vh;
        background: #000;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        text-align: center;
    }
}