#table {
    background-image: url('../../public/img/tables/table1.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
}

#table-title {
    font-family: "Playfair Display", 'Times New Roman', Times, serif;
	position: absolute;
	bottom: 55%;
	left: 35%;
	width: 30%;
	text-align: center;
	font-size: 3em;
	font-weight: bold;
	opacity: 0.15;
	color: #000;
}

.table-title-alt {
    opacity: 0.6;
}

/* Seats */
#seat1-player {
	position: absolute;
	bottom: 1%;
	left: 40%;
	width: 28%;
	height: 20%;
}

#seat2-player {
	position: absolute;
	bottom: 25%;
	left: 7%;
	width: 14%;
	height: 10%;
}

#seat3-player {
	position: absolute;
	bottom: 75%;
	left: 7%;
	width: 14%;
	height: 10%;
}

#seat4-player {
	position: absolute;
	bottom: 83%;
	left: 43%;
	width: 14%;
	height: 10%;
}

#seat5-player {
	position: absolute;
	bottom: 75%;
	right: 10%;
	width: 14%;
	height: 10%;
}

#seat6-player {
	position: absolute;
	bottom: 25%;
	right: 10%;
	width: 14%;
	height: 10%;
}

.bet-area {
	width: 12%;
	height: 6%;
}

#seat1-bet *, #seat2-bet *, #seat3-bet *, #seat4-bet * {
	float: left;
}
#seat5-bet *, #seat6-bet * {
	float: right;
}

.betting-chip {
	width: 15%;
	margin-left: -7%;
}

#seat5-bet .betting-chip, #seat6-bet .betting-chip {
	margin-left: 0%;
	margin-right: -7%;
}

#seat5-bet .bet-amount-text, #seat6-bet .bet-amount-text {
	margin-left: 0%;
	margin-right: 3%;
}

.dealer-chip {
	width: 25%;
	padding-right: 7%;
}

#seat5-bet .dealer-chip, #seat6-bet .dealer-chip {
	padding-right: 0%;
	padding-left: 7%;
}

.group-card {
	width: 18%;
}

.card {
	width: 40%;
    margin: 1%;
}

/* Player */
.player-container {
	position: relative;
}

.player-frame {
	width: 200px;
    position: absolute;
	bottom: -80px;
}

.cards-container {
    position: absolute;
    bottom: -20px;
}

.player-stack {
	position: absolute;
	left: 45px;
	bottom: 47px;
}

.player-name {
	position: absolute;
	left: 16px;
	bottom: -38px;
	font-size: 0.8em;
}

.player-avatar {
	height: 50px;
	position: absolute;
	left: 142px;
	bottom: -56px;
}

/* Seat 1 Modifications */

#seat1-cards {
    bottom: 5%;
}

#seat1-frame {
	width: 300px;
	bottom: -26px;
}

#seat1-stack {
	left: 60px;
	bottom: 70px;
	font-size: 1.3em
}

#seat1-status {
	left: 60px;
	bottom: 115px;
}

#seat1-name {
	left: 30px;
	bottom: 36px;
    font-size: 1.2em;
}

#seat1-avatar {
	height: 70px;
	left: 216px;
	bottom: 12px;
}

/* Center Table */
#pot-chips {
	position: absolute;
	bottom: 40%;
	left: 45%;
	width: 10%;
	text-align: center;
}

#pot {
	background: rgba(0,0,0,0.7);
	position: absolute;
	bottom: 38%;
	left: 44%;
    min-width: 8%;
	text-align: center;
	border-radius: 25px;
	font-size: 2em;
	padding: 0px 3%;
}

#pot-text, #pot-value {
	font-size: 1em;
}

#winner {
	background: rgba(0,0,0,0.5);
	position: absolute;
	bottom: 55%;
	left: 40%;
	width: 20%;
	text-align: center;
	border-radius: 99px;
	padding: 1%;
	font-size: 0.9em;
}

#table-notification {
	background: rgba(0,0,0,0.5);
	position: absolute;
	bottom: 55%;
	left: 30%;
	width: 40%;
	text-align: center;
	border-radius: 50%;
	padding: 2%;
	font-size: 0.9em;
}

#blinds {
	font-size: 0.3em;
}

#blind-clock {
	font-size: 0.3em;
}

/* Controls */
#checkboxes {
	position: fixed;
	bottom: 1%;
	left: 1%;
	text-align: left;
}

#action-buttons {
	letter-spacing: 0em;
	position: absolute;
	bottom: 1%;
	right: 5%;
	width: 25%;
	text-align: center;
	font-size: 0.7em;
}

.standard-button {
    background: linear-gradient(90deg, #222, #000) padding-box, linear-gradient(48.23deg, #22B879 20%, #7441D5 80%) border-box;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 15px;
    cursor: pointer;
    letter-spacing: 1px;
    padding: 1%;
    margin: 1%;
	text-align: center;
	display: inline-block;
	font-weight: bold;
}

.standard-button:hover {
    background: linear-gradient(90deg, #262626, #060606) padding-box, linear-gradient(48.23deg, #22B879 40%, #7441D5 60%) border-box;
}

.action-button {
	width: 28%;
    height: 40px;
	margin: 0 0.5%;
}

.percentage-button {
	width: 18%;
	font-size: 1em;
}

.slider-container {
	width: 100%;
	margin: 4% 0;
}

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 5px;
	border-radius: 5px;  
	background: linear-gradient(48.23deg, #22B879 20%, #7441D5 80%) border-box;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 2px solid #000;
	background: linear-gradient(48.23deg, #22B879 20%, #7441D5 80%) border-box;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 2px solid #000;
	background: linear-gradient(180deg, rgba(180, 48, 59,1) 20%, rgba(94,6,6,1) 100%);
	cursor: pointer;
}

#slider-value-display {
	font-size: 1.3em;
	margin-bottom: 10px;
}